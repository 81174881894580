"use client";

import { cn } from "@/lib/utils";
import { signIn } from "next-auth/react";
import { useTranslations } from "next-intl";
import Link from "next/link";
import * as React from "react";
import {
  Button,
  FacebookIcon,
  GoogleIcon,
  Input,
  Label,
  LoaderIcon,
  SpotifyIcon,
} from "ui";

interface LoginFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function LoginForm({ className, ...props }: LoginFormProps) {
  const t = useTranslations();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    await signIn("google", {
      callbackUrl: `${window.location.origin}`,
    });
  };

  const handleFacebookLogin = async () => {
    setIsLoading(true);
    await signIn("facebook", {
      callbackUrl: `${window.location.origin}`,
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await signIn("email", {
        callbackUrl: `${window.location.origin}`,
        email,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <Label className="" htmlFor="email">
              {t("auth.login.form.email.label")}
            </Label>
            <Input
              id="email"
              placeholder={t("auth.login.form.email.placeholder")}
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
              required
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
          <Button loading={isLoading}>
            {t("auth.login.form.email.submit")}
          </Button>
        </div>
      </form>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            {t("auth.login.form.or")}
          </span>
        </div>
      </div>
      <div className="grid gap-2">
        <Button loading={isLoading} onClick={handleGoogleLogin}>
          <GoogleIcon className="h-4 w-4 mr-2" />
          {t("auth.login.form.google.button")}
        </Button>
      </div>

      <p className="px-8 text-center text-sm text-muted-foreground">
        {t.rich("auth.legalNotice", {
          termLink: (children) => {
            return (
              <Link
                href={t("auth.termLink")}
                className="underline underline-offset-4 hover:text-primary"
                target="_blank"
              >
                {children}
              </Link>
            );
          },
          privacyLink: (children) => {
            return (
              <Link
                href={t("auth.privacyLink")}
                className="underline underline-offset-4 hover:text-primary"
                target="_blank"
              >
                {children}
              </Link>
            );
          },
        })}
      </p>
    </div>
  );
}
